import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import shopsReducer from './shops';
import commonReducer from './common';
import groupsReducer from './groups';
import productsReducer from './products';
import ordersReducer from './orders';
import supportReducer from './support/model';

const rootReducer = combineReducers({
  user: userReducer,
  shops: shopsReducer,
  common: commonReducer,
  groups: groupsReducer,
  products: productsReducer,
  orders: ordersReducer,
  support: supportReducer,
});

export const setupStore = () => {
  return configureStore({ reducer: rootReducer });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
