import { RootState } from 'src/store/store';

export const selectTickets = (state: RootState) => state.support.tickets;
export const selectOrders = (state: RootState) => state.support.orders;
export const selectTopics = (state: RootState) => state.support.topics;

export const selectTicketStatuses = (state: RootState) => state.support.ticketStatuses;

export const selectUserTicketInfo = (state: RootState) => state.support.chatTicketInfo;
export const selectChatMessages = (state: RootState) => state.support.chatMessages;
export const selectChatMessagesLoading = (state: RootState) => state.support.chatMessagesLoading;

export const selectOrderInfo = (state: RootState) => state.support.orderInfo;
export const selectOrderLogs = (state: RootState) => state.support.orderLogs;
