import { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react';
import { PunctureIcon } from 'src/assets/icons/kit/Puncture';
import { UserType } from 'src/config/types';
import {
  Order,
  OrderLog,
  Status,
  Ticket,
  UpdateTicketPayload,
  UserMessage,
} from 'src/store/support/model/types';
import { MessageBubble } from '../MessageBubble';

export const ChatMessages: React.FC<{
  messages: UserMessage[];
  user: UserType | null;
  chatRef: MutableRefObject<HTMLDivElement | null>;
  selectedIncident: Ticket;
  ticketStatuses: Status[];
  orderInfo: Order | null;
  orderLogs: OrderLog[];
  onChangeTicketStatus: ({ id, status }: UpdateTicketPayload) => void;
  onLogPayloadClick: (payload) => void;
  isLogsModalOpen: boolean;
  setIsLogsModalOpen: Dispatch<boolean>;
}> = ({
  messages,
  user,
  chatRef,
  selectedIncident,
  ticketStatuses,
  onChangeTicketStatus,
  orderInfo,
  orderLogs,
  onLogPayloadClick,
  isLogsModalOpen,
  setIsLogsModalOpen,
}) => {
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages, chatRef]);

  return (
    <>
      <div className="sticky top-0 z-10 bg-white relative w-full h-[41px] flex items-center justify-start gap-[6px] border border-violet-100 mb-3">
        <div className="ml-[13px]">
          <PunctureIcon />
        </div>
        <span className="text-[14px] text-white-grey">Обращение:</span>
        <span className="text-[14px] text-violet-200 cursor-pointer">
          {selectedIncident.userId} - {selectedIncident.id}
        </span>
      </div>
      <div ref={chatRef} className="flex-1 overflow-auto pb-4 pr-2">
        {messages.map((message, index) => (
          <MessageBubble
            selectedIncident={selectedIncident}
            key={message.id}
            message={message}
            onChangeTicketStatus={onChangeTicketStatus}
            user={user}
            isFirst={index === 0}
            ticketStatuses={ticketStatuses}
            orderInfo={orderInfo}
            orderLogs={orderLogs}
            onLogPayloadClick={onLogPayloadClick}
            isLogsModalOpen={isLogsModalOpen}
            setIsLogsModalOpen={setIsLogsModalOpen}
          />
        ))}
      </div>
    </>
  );
};
