import { Dispatch, SetStateAction, useState } from 'react';
import { Modal } from '../kit/Modal';
import { OrderLog, Ticket } from 'src/store/support/model/types';
import { LogTable } from '../LogTable';
import { BackIcon } from 'src/assets/icons/kit/BackIcon';

interface OrderLogsModalProps {
  isLogsModalOpen: boolean;
  setIsLogsModalOpen: (isOpen: boolean) => void;
  orderLogs: OrderLog[];
  selectedIncident: Ticket;
  onLogPayloadClick: (payload) => void;
}

const mockOrderLogs = [
  {
    id: 1,
    message: 'Авторизация',
    type: 'auth',
    createdAt: '14:07 31.01.2025',
    orderId: 123,
    user: 'John Smith',
    payload: 'Открыть',
    status: 'Создано новое обращение',
  },
  {
    id: 2,
    message: 'Выход из системы',
    type: 'logout',
    createdAt: '14:10 31.01.2025',
    orderId: 124,
    user: 'Jane Doe',
    payload: 'Открыть',
    status: 'Закрыто',
  },
  {
    id: 3,
    message: 'Изменение пароля',
    type: 'password_change',
    createdAt: '15:00 31.01.2025',
    orderId: 125,
    user: 'Alice Johnson',
    payload: 'Открыть',
    status: 'В ожидании',
  },
  {
    id: 4,
    message: 'Попытка входа',
    type: 'login_attempt',
    createdAt: '15:30 31.01.2025',
    orderId: 126,
    user: 'Bob Brown',
    payload: 'Открыть',
    status: 'Ошибка',
  },
  {
    id: 5,
    message: 'Авторизация',
    type: 'auth',
    createdAt: '16:00 31.01.2025',
    orderId: 127,
    user: 'Charlie White',
    payload: 'Открыть',
    status: 'Создано новое обращение',
  },
  {
    id: 6,
    message: 'Обновление профиля',
    type: 'profile_update',
    createdAt: '17:20 31.01.2025',
    orderId: 128,
    user: 'David Black',
    payload: 'Открыть',
    status: 'В обработке',
  },
  {
    id: 7,
    message: 'Авторизация',
    type: 'auth',
    createdAt: '18:10 31.01.2025',
    orderId: 129,
    user: 'Emma Stone',
    payload: 'Открыть',
    status: 'Создано новое обращение',
  },
  {
    id: 8,
    message: 'Удаление аккаунта',
    type: 'account_delete',
    createdAt: '19:45 31.01.2025',
    orderId: 130,
    user: 'George King',
    payload: 'Открыть',
    status: 'Закрыто',
  },
  {
    id: 9,
    message: 'Авторизация',
    type: 'auth',
    createdAt: '20:00 31.01.2025',
    orderId: 131,
    user: 'Hannah Green',
    payload: 'Открыть',
    status: 'Создано новое обращение',
  },
  {
    id: 10,
    message: 'Смена почты',
    type: 'email_change',
    createdAt: '21:15 31.01.2025',
    orderId: 132,
    user: 'Ivan Petrov',
    payload: 'Открыть',
    status: 'В ожидании',
  },
];

export const OrderLogsModal = ({
  isLogsModalOpen,
  setIsLogsModalOpen,
  orderLogs = mockOrderLogs,
  selectedIncident,
  onLogPayloadClick,
}: OrderLogsModalProps) => {
  return (
    <Modal
      isOpen={isLogsModalOpen}
      onClose={() => setIsLogsModalOpen(false)}
      classNames={`max-w-[1497px] max-h-[863px] px-6 width-[100%]`}
    >
      <div className="flex flex-start items-center mb-[24px] gap-[10px]">
        <button onClick={() => setIsLogsModalOpen(false)} className="text-lg font-medium">
          <BackIcon />
        </button>
        <h2 className="text-xl font-semibold text-[30px]">
          {selectedIncident?.userId} - {selectedIncident?.id}
        </h2>
        <h5 className="text-[#33384F] text-[14px]">{orderLogs.length} записей</h5>
      </div>
      <LogTable data={mockOrderLogs} onPayloadClick={onLogPayloadClick} />
    </Modal>
  );
};
