export const CheckboxCheckIcon = className => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M1.3479 5.12984L5.66768 9.91245L13.5218 1.2168" stroke="white" stroke-width="2" />
    </svg>
  );
};
