import { UserType } from 'src/config/types';
import {
  Order,
  OrderLog,
  Status,
  Ticket,
  UpdateTicketPayload,
  UserMessage,
} from 'src/store/support/model/types';
import cn from 'classnames';
import { ChatCheckIcon } from 'src/assets/icons/kit/ChatCheckIcon';
import { formatRussianDate } from 'src/utils';
import { FirstMessageTextBlock } from '../FirstMessageTextBlock';
import { DefaultMessageTextBlock } from '../DefaultMessageTextBlock';
import { Dispatch } from 'react';

export const MessageBubble: React.FC<{
  message: UserMessage;
  user: UserType | null;
  isFirst: boolean;
  ticketStatuses: Status[];
  selectedIncident: Ticket;
  orderInfo: Order | null;
  orderLogs: OrderLog[];
  onChangeTicketStatus: ({ id, status }: UpdateTicketPayload) => void;
  onLogPayloadClick: (payload) => void;
  isLogsModalOpen: boolean;
  setIsLogsModalOpen: Dispatch<boolean>;
}> = ({
  message,
  user,
  isFirst,
  ticketStatuses,
  selectedIncident,
  onChangeTicketStatus,
  orderInfo,
  orderLogs,
  onLogPayloadClick,
  isLogsModalOpen,
  setIsLogsModalOpen,
}) => {
  const isUser = message.userId !== user?.id;
  const bubbleColor = isUser ? 'bg-[#F3F3F3]' : 'bg-[#E8DDF9]';

  return (
    <div className={cn('mb-4 flex w-full', isUser ? 'justify-start' : 'justify-end')}>
      <div className="flex max-w-[80%] flex-col">
        <div className="flex items-start">
          {isUser && (
            <div className="ml-2 flex h-[38px] w-[38px] min-w-[38px] items-center justify-center rounded-full bg-[#E8DDF9] text-[17px] font-bold text-[#8654CC]">
              П
            </div>
          )}
          <div className="flex flex-col">
            <div
              className={`mb-1 flex items-center text-xs text-gray-400 ${
                isUser ? 'justify-start' : 'justify-end'
              }`}
            >
              {isUser ? (
                <div className="flex items-center">
                  <span className={cn('ml-1 mr-1 text-[11px] text-[#7B7B7B]')}>
                    {formatRussianDate({ isoDate: message.createdAt, isShowTime: true })}
                  </span>
                  {/* //TODO: Когда будут статусы поставить их */}
                  {/* {isRead &&  */}
                  <ChatCheckIcon />
                </div>
              ) : (
                <div className="flex items-center">
                  <span className={cn('ml-1 mr-1 text-[11px] text-[#7B7B7B]')}>
                    {formatRussianDate({ isoDate: message.createdAt, isShowTime: true })}
                  </span>
                </div>
              )}
            </div>
            {isFirst ? (
              <FirstMessageTextBlock
                message={message}
                bubbleColor={bubbleColor}
                ticketStatuses={ticketStatuses}
                selectedIncident={selectedIncident}
                onChangeTicketStatus={onChangeTicketStatus}
                orderInfo={orderInfo}
                orderLogs={orderLogs}
                onLogPayloadClick={onLogPayloadClick}
                isLogsModalOpen={isLogsModalOpen}
                setIsLogsModalOpen={setIsLogsModalOpen}
              />
            ) : (
              <DefaultMessageTextBlock
                message={message}
                bubbleColor={bubbleColor}
                isUser={isUser}
              />
            )}
          </div>
          {!isUser && (
            <div className="ml-2 flex h-[38px] w-[38px] min-w-[38px] items-center justify-center rounded-full bg-[#E8DDF9] text-[17px] font-bold text-[#8654CC]">
              A
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
