import { FC } from 'react';
import cn from 'classnames';

interface LogoProps {
  small?: boolean;
}

export const Logo: FC<LogoProps> = ({ small }) => {
  return (
    <div
      className={cn(
        {
          'w-[4.5rem] min-w-[4.5rem] h-[4.5rem]': !Boolean(small),
          'w-10 min-w-[2rem] h-10': Boolean(small),
        },
        'rounded-full bg-violet-100 flex items-center justify-center',
      )}
    >
      <div
        className={cn(
          { 'text-3xl ': !Boolean(small), 'text-lg ': Boolean(small) },
          'text-violet-200 font-gilroy-700 cursor-default select-none',
        )}
      >
        А
      </div>
    </div>
  );
};
