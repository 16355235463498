export const isDevApi = process.env.REACT_APP_BUILD === 'development';
export const isProdApi = process.env.REACT_APP_BUILD === 'production';
export const isStageApi = process.env.REACT_APP_BUILD === 'stage';

export const WS_SITE_LINK = 'https://winsolutions.ru/';
export const WS_SUPPORT_EMAIL = 'BreezeBot@winsolutions.ru';
export const WS_SUPPORT_BASE_URL = 'https://site-back-dev.bots.winsolutions.ru/api';

export const BASE_URL = isProdApi
  ? 'https://auth.breezebot.ru/api'
  : isStageApi
  ? 'https://breeze-bot-auth-stage.bots.winsolutions.ru/api'
  : 'https://breeze-bot-auth-dev.bots.winsolutions.ru/api';

export const SHOP_BASE_URL = isProdApi
  ? 'https://catalog.breezebot.ru/api'
  : isStageApi
  ? 'https://breeze-bot-catalog-stage.bots.winsolutions.ru/api'
  : 'https://breeze-bot-catalog-dev.bots.winsolutions.ru/api';

export const SUPPORT_BASE_URL = isProdApi
  ? 'https://api.breezebot.ru/api'
  : isStageApi
  ? 'https://breeze-bot-telegramapi-stage.bots.winsolutions.ru/api'
  : 'https://breeze-bot-telegramapi-dev.bots.winsolutions.ru/api';

export const PAYMENT_BASE_URL = isProdApi
  ? 'https://payment.breezebot.ru/api'
  : isStageApi
  ? 'https://breeze-bot-payment-back-stage.bots.winsolutions.ru/api'
  : 'https://breeze-bot-payment-back-dev.bots.winsolutions.ru/api';

export const SUPPORT_URL = 'https://tech-support-dev.bots.winsolutions.ru/api';
