import { useEffect, useRef } from 'react';
import cn from 'classnames';

interface DropdownProps {
  isOpen: boolean;
  onClose: () => void;
  toggle: () => void;
  renderTrigger: () => React.ReactNode;
  renderOptions: () => React.ReactNode;
  containerClassName?: string;
  optionsWrapperClassName?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  isOpen,
  onClose,
  toggle,
  renderTrigger,
  renderOptions,
  containerClassName,
  optionsWrapperClassName,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  return (
    <div className={cn('relative inline-block', containerClassName)} ref={containerRef}>
      <div onClick={toggle}>{renderTrigger()}</div>
      {isOpen && (
        <div className={cn('absolute z-10', optionsWrapperClassName)}>{renderOptions()}</div>
      )}
    </div>
  );
};
