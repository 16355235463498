import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { SupportUsersList } from 'src/components/SupportUsersList';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { useSelector } from 'react-redux';
import {
  fetchTicketTopics,
  selectTickets,
  fetchSupportTickets,
  postSupportMessage,
  fetchTicketStatuses,
  fetchOrderInfo,
} from 'src/store/support';
import { Attachment, Ticket, UpdateTicketPayload } from 'src/store/support/model/types';
import { SelectUserIcon } from 'src/assets/icons/kit/SelectUser';
import { MessageInput } from 'src/components/MessageInput';
import { useUserChatSocket } from 'src/hooks/useUserChatSocket';
import { Loader } from 'src/components/Loader';
import {
  selectOrderInfo,
  selectOrderLogs,
  selectTicketStatuses,
  selectTopics,
} from 'src/store/support/model/getters';
import { debounce } from 'src/utils';
import { AttachmentsPanel } from 'src/components/AttachmentsPanel';
import { putUserTicket } from 'src/store/support';
import { ChatMessages } from 'src/components/ChatMessagesList';
import { fetchOrderLogs } from 'src/store/support/model/actions';
import { JsonViewer } from 'src/components/JsonViewer';
import { BackIcon } from 'src/assets/icons/kit/BackIcon';

export const Support: FC = () => {
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(state => state.user);
  const incidents = useSelector(selectTickets);
  const topics = useSelector(selectTopics);
  const ticketStatuses = useSelector(selectTicketStatuses);
  const orderInfo = useSelector(selectOrderInfo);
  const orderLogs = useSelector(selectOrderLogs);

  const [content, setContent] = useState('');
  const [selectedIncident, setSelectedIncident] = useState<Ticket | null>(null);
  const [localSearch, setLocalSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false);
  const [supportLogPayload, setSupportLogPayload] = useState(null);

  const chatEndRef = useRef<HTMLDivElement | null>(null);

  const { chatMessages, chatMessagesLoading } = useUserChatSocket({
    user_id: user?.id,
    ticket_id: selectedIncident?.id,
  });

  const handleDebouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        setDebouncedSearch(value);
      }, 300),
    [],
  );

  const onChangeTicketStatus = (data: UpdateTicketPayload) => {
    dispatch(putUserTicket(data));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocalSearch(value);
    handleDebouncedSearch(value);
  };

  const filteredIncidents = useMemo(() => {
    const search = debouncedSearch.trim().toLowerCase();

    if (!search) {
      return incidents;
    }

    return incidents.filter(ticket => {
      const titleStr = ticket.title?.toLowerCase() || '';
      const idStr = String(ticket.id).toLowerCase();
      const userIdStr = String(ticket.userId).toLowerCase();

      return titleStr.includes(search) || idStr.includes(search) || userIdStr.includes(search);
    });
  }, [incidents, debouncedSearch]);

  const onCreateUserMessage = () => {
    if (!content.trim() && !attachments) return;
    if (!selectedIncident) return;

    dispatch(
      postSupportMessage({
        user_id: user?.id,
        ticket_id: selectedIncident.id,
        content,
        ...(!!attachments.length && { files_data: attachments }),
      }),
    );

    setContent('');
    setAttachments([]);
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onLogPayloadClose = () => {
    setSupportLogPayload(null);
    setIsLogsModalOpen(true);
  };

  const onLogPayloadClick = payload => {
    setSupportLogPayload(payload);
    setIsLogsModalOpen(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onCreateUserMessage();
    }
  };

  useEffect(() => {
    dispatch(fetchTicketTopics());
    dispatch(fetchTicketStatuses());
  }, [dispatch]);

  useEffect(() => {
    selectedIncident?.orderId && dispatch(fetchOrderLogs(String(selectedIncident?.orderId)));
  }, [selectedIncident]);

  useEffect(() => {
    if (user) {
      dispatch(
        fetchSupportTickets({
          user_id: user.id,
          ...(selectedStatuses.length && {
            status: selectedStatuses.join(','),
          }),
          ...(selectedTopics.length && {
            topics: selectedTopics.join(','),
          }),
        }),
      );
    }
  }, [user, selectedStatuses, selectedTopics, dispatch]);

  if (supportLogPayload) {
    return (
      <div className="flex flex-col h-full w-full t-lg:bg-white relative z-10">
        <div className="flex flex-start items-center mb-[24px] mt-[24px] gap-[12px]">
          <button onClick={onLogPayloadClose} className="text-lg font-medium">
            <BackIcon />
          </button>
          <h2 className="text-xl font-medium text-[30px]">
            {selectedIncident?.userId} - {selectedIncident?.id}
          </h2>
          <h5 className="text-[#33384F] text-[14px]">{orderLogs.length} записей</h5>
        </div>
        <JsonViewer data={supportLogPayload} />
      </div>
    );
  }

  return (
    <div className="flex h-full">
      <SupportUsersList
        incidents={filteredIncidents}
        loadingIncidents={false}
        selectedIncident={selectedIncident}
        handleSelectIncident={setSelectedIncident}
        search={localSearch}
        setSearch={handleChange}
        ticketStatuses={ticketStatuses}
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
        selectedTopics={selectedTopics}
        setSelectedTopics={setSelectedTopics}
        topics={topics}
      />
      {selectedIncident ? (
        <div className="h-full w-full">
          {!selectedIncident ? (
            <div className="w-full h-full justify-center flex flex-col items-center gap-8">
              <SelectUserIcon />
              <div className="flex items-center justify-center font-gilroy-500 text-2xl">
                Выберите пользователя
              </div>
            </div>
          ) : null}
          {selectedIncident ? (
            <main className="flex flex-1 flex-col p-4 h-[calc(100vh-64px)]">
              {chatMessagesLoading ? (
                <div className="h-full w-full flex items-center justify-center">
                  <Loader classNames="w-1/6 h-1/6" />
                </div>
              ) : (
                <>
                  <ChatMessages
                    messages={chatMessages}
                    user={user}
                    chatRef={chatEndRef}
                    onChangeTicketStatus={onChangeTicketStatus}
                    selectedIncident={selectedIncident}
                    ticketStatuses={ticketStatuses}
                    orderInfo={orderInfo}
                    orderLogs={orderLogs}
                    onLogPayloadClick={onLogPayloadClick}
                    isLogsModalOpen={isLogsModalOpen}
                    setIsLogsModalOpen={setIsLogsModalOpen}
                  />
                  <AttachmentsPanel
                    attachments={attachments}
                    onChangeAttachments={setAttachments}
                  />
                  <MessageInput
                    onChange={setContent}
                    value={content}
                    onSubmit={onCreateUserMessage}
                    onFilesChange={setAttachments}
                  />
                </>
              )}
            </main>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
