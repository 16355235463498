export const AdminAttachIcon = className => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.8561 16.7972C1.82528 14.8414 1.86313 11.6876 3.90427 9.73897L11.1948 2.77874C12.7351 1.30819 15.2388 1.30806 16.7792 2.77874C18.3046 4.23507 18.3065 6.58482 16.7792 8.04287L10.424 14.1042C9.38632 15.0948 7.69096 15.081 6.67089 14.0719C5.68815 13.0997 5.71968 11.5587 6.72136 10.6024L11.7174 5.83914C11.9322 5.63431 12.2843 5.63081 12.5038 5.83132L13.2983 6.55728C13.5178 6.75782 13.5215 7.08643 13.3067 7.29125L8.31123 12.054C8.13981 12.2177 8.12924 12.4896 8.28871 12.6474C8.44066 12.7977 8.67954 12.8002 8.83397 12.6528L15.1892 6.59144C15.8708 5.94064 15.8708 4.88107 15.1888 4.22991C14.5218 3.59323 13.4525 3.5929 12.7852 4.22991L5.49466 11.1901C4.28642 12.3437 4.26779 14.2109 5.45327 15.3525C6.63534 16.4909 8.54608 16.4923 9.73034 15.3618L15.7105 9.65248C15.9252 9.4475 16.2773 9.4438 16.497 9.64418L17.2921 10.3697C17.5117 10.57 17.5157 10.8986 17.301 11.1036L11.3208 16.8129C9.25011 18.7897 5.90072 18.7663 3.8561 16.7972Z"
        fill="#7B7B7B"
      />
    </svg>
  );
};
