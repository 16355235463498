import { useState } from 'react';
import { UserIcon } from 'src/assets/icons/kit/User';
import { Modal } from '../kit/Modal';
import { MailIcon } from 'src/assets/icons/kit/Mail';
import { Order } from 'src/store/support/model/types';

interface UserInfoModalProps {
  isUserInfoModalOpen: boolean;
  setIsUserInfoModalOpen: (isOpen: boolean) => void;
  orderInfo: Order | null;
}

export const UserInfoModal = ({
  isUserInfoModalOpen,
  setIsUserInfoModalOpen,
  orderInfo,
}: UserInfoModalProps) => {
  const [step, setStep] = useState(1);

  const modalWidth = step === 1 ? '750px' : step === 2 ? '416px' : '';

  const handleClose = () => {
    setStep(1);
    setIsUserInfoModalOpen(false);
  };

  return (
    <Modal
      isOpen={isUserInfoModalOpen}
      onClose={handleClose}
      classNames={`max-h-[229px] max-w-[${modalWidth}] p-6 width-[100%]`}
    >
      {step === 1 ? (
        <div className="flex flex-col gap-[32px]">
          <div className="text-[20px] flex justify-center">
            Вы уверены что хотите получить личные данные пользователя?
          </div>
          <div className="h-[53px] gap-[20px] flex">
            <div
              onClick={handleClose}
              className="w-[50%] border-2 border-violet-200 rounded-full text-violet-200 text-[20px] flex flex-col justify-center items-center cursor-pointer"
            >
              Отмена
            </div>
            <div
              onClick={() => setStep(2)}
              className="w-[50%] bg-violet-200 rounded-full text-white text-[20px] flex flex-col justify-center items-center cursor-pointer"
            >
              Подтвердить
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          <div className="flex items-center gap-[12px]">
            <UserIcon />
            <div className="text-[20px]">Морару Валентин Морару</div>
          </div>

          <div className="flex items-center gap-[12px]">
            <MailIcon />
            <div className="text-[20px]">Valentin.valentin777@mail.ru</div>
          </div>
        </div>
      )}
    </Modal>
  );
};
