import { createAsyncThunk } from '@reduxjs/toolkit';
import { ShopType } from 'src/config/types';
import shopService from 'src/services/ShopService';

export const fetchShops = createAsyncThunk('shops/fetch', async (_, thunkAPI) => {
  try {
    const response = await shopService.getShops();

    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить пользователя');
  }
});

export const fetchPresets = createAsyncThunk('shops/fetchPresets', async (_, thunkAPI) => {
  try {
    const response = await shopService.getPresets();

    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить пресеты');
  }
});

export const postShopSwitcher = createAsyncThunk(
  'shops/postShopSwitcher',
  async (data: ShopType, thunkAPI) => {
    try {
      const response = await shopService.updateShop(data, data.id);

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось обновить свитчер');
    }
  },
);
