export const CalendarIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 15.4062C1 16.2861 1.71987 17 2.60714 17H14.3929C15.2801 17 16 16.2861 16 15.4062V6.375H1V15.4062ZM11.7143 8.89844C11.7143 8.6793 11.8951 8.5 12.1161 8.5H13.4554C13.6763 8.5 13.8571 8.6793 13.8571 8.89844V10.2266C13.8571 10.4457 13.6763 10.625 13.4554 10.625H12.1161C11.8951 10.625 11.7143 10.4457 11.7143 10.2266V8.89844ZM11.7143 13.1484C11.7143 12.9293 11.8951 12.75 12.1161 12.75H13.4554C13.6763 12.75 13.8571 12.9293 13.8571 13.1484V14.4766C13.8571 14.6957 13.6763 14.875 13.4554 14.875H12.1161C11.8951 14.875 11.7143 14.6957 11.7143 14.4766V13.1484ZM7.42857 8.89844C7.42857 8.6793 7.60938 8.5 7.83036 8.5H9.16964C9.39062 8.5 9.57143 8.6793 9.57143 8.89844V10.2266C9.57143 10.4457 9.39062 10.625 9.16964 10.625H7.83036C7.60938 10.625 7.42857 10.4457 7.42857 10.2266V8.89844ZM7.42857 13.1484C7.42857 12.9293 7.60938 12.75 7.83036 12.75H9.16964C9.39062 12.75 9.57143 12.9293 9.57143 13.1484V14.4766C9.57143 14.6957 9.39062 14.875 9.16964 14.875H7.83036C7.60938 14.875 7.42857 14.6957 7.42857 14.4766V13.1484ZM3.14286 8.89844C3.14286 8.6793 3.32366 8.5 3.54464 8.5H4.88393C5.10491 8.5 5.28571 8.6793 5.28571 8.89844V10.2266C5.28571 10.4457 5.10491 10.625 4.88393 10.625H3.54464C3.32366 10.625 3.14286 10.4457 3.14286 10.2266V8.89844ZM3.14286 13.1484C3.14286 12.9293 3.32366 12.75 3.54464 12.75H4.88393C5.10491 12.75 5.28571 12.9293 5.28571 13.1484V14.4766C5.28571 14.6957 5.10491 14.875 4.88393 14.875H3.54464C3.32366 14.875 3.14286 14.6957 3.14286 14.4766V13.1484ZM14.3929 2.125H12.7857V0.53125C12.7857 0.239062 12.5446 0 12.25 0H11.1786C10.8839 0 10.6429 0.239062 10.6429 0.53125V2.125H6.35714V0.53125C6.35714 0.239062 6.11607 0 5.82143 0H4.75C4.45536 0 4.21429 0.239062 4.21429 0.53125V2.125H2.60714C1.71987 2.125 1 2.83887 1 3.71875V5.3125H16V3.71875C16 2.83887 15.2801 2.125 14.3929 2.125Z"
        fill="#8654CC"
      />
    </svg>
  );
};
