export const ArrowUpIcon = () => {
  return (
    <svg width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6289 0.862158L0.65143 9.59245C0.170227 10.0135 0.170227 10.6961 0.65143 11.1171L1.81514 12.1354C2.29552 12.5557 3.07408 12.5565 3.55559 12.1372L11.5001 5.21828L19.4446 12.1372C19.9261 12.5565 20.7047 12.5557 21.1851 12.1354L22.3488 11.1171C22.83 10.6961 22.83 10.0135 22.3488 9.59245L12.3714 0.862158C11.8902 0.44115 11.1101 0.44115 10.6289 0.862158Z"
        fill="white"
      />
    </svg>
  );
};
