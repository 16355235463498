export const FilterIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4280_4234)">
        <path
          d="M15.5044 1.0625H2.55841C1.23118 1.0625 0.561526 2.67278 1.5019 3.61315L6.54102 8.65404V14.0117C6.54102 14.482 6.76243 14.9249 7.13867 15.2568L9.13086 16.7219C10.1043 17.4022 11.5215 16.7698 11.5215 15.5266V8.65404L16.5609 3.61315C17.4994 2.67468 16.8342 1.0625 15.5044 1.0625ZM10.0273 8.03516V15.5059L8.03516 14.0117V8.03516L2.55664 2.55664H15.5059L10.0273 8.03516Z"
          fill="#8654CC"
        />
      </g>
      <defs>
        <clipPath id="clip0_4280_4234">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
