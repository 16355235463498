import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Order, OrderLog, Status, Ticket, Topics, UserMessage } from './types';
import { fetchChatTicketInfo, putUserTicket } from './actions';

interface SupportState {
  tickets: Ticket[];
  orders: Order[];
  topics: Topics[];

  ticketStatuses: Status[];

  chatTicketInfo: Ticket | null;
  chatMessages: UserMessage[];
  chatMessagesLoading: boolean;

  orderInfo: Order | null;
  orderLogs: OrderLog[];
}

const initialState: SupportState = {
  tickets: [],
  orders: [],
  topics: [],

  ticketStatuses: [],

  chatTicketInfo: null,
  chatMessages: [],
  chatMessagesLoading: false,

  orderInfo: null,
  orderLogs: [],
};

const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setAppeals(state, action: PayloadAction<Ticket[]>) {
      state.tickets = action.payload;
    },
    setOrders(state, action: PayloadAction<Order[]>) {
      state.orders = action.payload;
    },
    setTopics(state, action: PayloadAction<Topics[]>) {
      state.topics = action.payload;
    },
    setChatMessages(state, action: PayloadAction<any>) {
      state.chatMessages = action.payload;
    },
    setChatTicketInfo(state, action: PayloadAction<Ticket>) {
      state.chatTicketInfo = action.payload;
    },
    setChatMessagesLoading(state, action: PayloadAction<boolean>) {
      state.chatMessagesLoading = action.payload;
    },
    setTicketStatuses(state, action: PayloadAction<Status[]>) {
      state.ticketStatuses = action.payload;
    },
    setOrderInfo(state, action: PayloadAction<Order>) {
      state.orderInfo = action.payload;
    },
    setOrderLogs(state, action: PayloadAction<OrderLog[]>) {
      state.orderLogs = action.payload;
    },
    setChatMessagesFromSockets(state, action: PayloadAction<UserMessage>) {
      if (Array.isArray(state.chatMessages)) {
        state.chatMessages.push(action.payload);
      } else {
        state.chatMessages = [action.payload];
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchChatTicketInfo.fulfilled, (state, action) => {
      const updatedTicket: Ticket = action.payload;
      const index = state.tickets.findIndex(ticket => ticket?.id === updatedTicket?.id);
      if (index !== -1 && state.tickets[index].unreadMessagesCount !== 0) {
        state.tickets[index].unreadMessagesCount = 0;
      }
    });
  },
});

export const {
  setAppeals,
  setOrders,
  setTopics,
  setChatMessages,
  setChatMessagesFromSockets,
  setChatTicketInfo,
  setChatMessagesLoading,
  setTicketStatuses,
  setOrderInfo,
  setOrderLogs,
} = supportSlice.actions;

const reducer = supportSlice.reducer;
export default reducer;
