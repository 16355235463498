import axios from 'axios';
import { SUPPORT_URL } from 'src/config';
import TokenService from 'src/services/TokenService';
import AuthService from 'src/services/AuthService';

export const ApiSupport = axios.create({
  baseURL: SUPPORT_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

ApiSupport.interceptors.request.use(
  config => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

ApiSupport.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const originalConfig = err.config;

    if (
      originalConfig.url !== '/Auth/login' &&
      originalConfig.url !== '/Auth/logout' &&
      err.response
    ) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          await AuthService.refreshToken();

          return ApiSupport(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  },
);
