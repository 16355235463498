import React, { useState } from 'react';
import DatePicker, { registerLocale, ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { getDay } from 'date-fns';
import { ru } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ru', ru);

interface CalendarPickerProps {
  selectedDate: Date | null;
  onChange: (date: Date | null) => void;
  isOpen: boolean;
  onRequestClose?: () => void;
}

export const CalendarPicker: React.FC<CalendarPickerProps> = ({
  selectedDate,
  onChange,
  isOpen,
  onRequestClose,
}) => {
  const [currentDate, setCurrentDate] = useState<Date | null>(selectedDate);

  const CustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
    const { date, changeYear, changeMonth } = props;
    const years = Array.from({ length: 11 }, (_, i) => 2020 + i);
    const months = [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ];

    const stopClickPropagation = (e: React.MouseEvent<HTMLSelectElement | HTMLOptionElement>) => {
      e.stopPropagation();
      // e.nativeEvent.stopImmediatePropagation();
    };

    return (
      <div className="flex items-center justify-between gap-2 mb-2">
        <select
          className="bg-[#F5F5F5] text-[12px] px-2 py-1 rounded outline-none"
          value={months[date.getMonth()]}
          onChange={e => changeMonth(months.indexOf(e.target.value))}
          onMouseDown={stopClickPropagation}
          onClick={stopClickPropagation}
        >
          {months.map(month => (
            <option
              key={month}
              value={month}
              onMouseDown={stopClickPropagation}
              onClick={stopClickPropagation}
            >
              {month}
            </option>
          ))}
        </select>
        <select
          className="bg-[#F5F5F5] text-[12px] px-2 py-1 rounded outline-none"
          value={date.getFullYear()}
          onChange={e => changeYear(parseInt(e.target.value, 10))}
          onMouseDown={stopClickPropagation}
          onClick={stopClickPropagation}
        >
          {years.map(year => (
            <option
              key={year}
              value={year}
              onMouseDown={stopClickPropagation}
              onClick={stopClickPropagation}
            >
              {year}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <DatePicker
      selected={currentDate ?? undefined}
      onChange={date => {
        setCurrentDate(date);
        onChange(date);
      }}
      open={isOpen}
      onCalendarClose={onRequestClose}
      shouldCloseOnSelect={false}
      locale="ru"
      dateFormat="d MMMM yyyy"
      showPopperArrow={false}
      popperPlacement="bottom-start"
      renderCustomHeader={props => <CustomHeader {...props} />}
      renderDayContents={(day, date) => {
        const weekday = getDay(date);
        const isWeekend = weekday === 0 || weekday === 6;
        return <span className={isWeekend ? 'text-red-500' : ''}>{day}</span>;
      }}
    />
  );
};
