import React from 'react';
import { AdminCloseIcon } from 'src/assets/icons/kit/AdminClose';
import { Attachment } from 'src/store/support/model/types';

interface AttachmentsPanelProps {
  attachments: Attachment[];
  onChangeAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>;
}

export const AttachmentsPanel: React.FC<AttachmentsPanelProps> = ({
  attachments,
  onChangeAttachments,
}) => {
  if (!attachments.length) return null;

  const removeFile = (index: number) => {
    onChangeAttachments(prev => prev.filter((_, i) => i !== index));
  };

  const removeAllFiles = () => {
    onChangeAttachments([]);
  };

  return (
    <div className="relative border-2 border-dashed border-[#DFE0E3] rounded-md p-3 flex bg-[#F5F6F8] flex-wrap items-start gap-4 flex justify-center">
      <div
        onClick={removeAllFiles}
        className="absolute top-[-4px] right-[-4px] bg-[#8654CC] p-2 rounded-full cursor-pointer"
      >
        <AdminCloseIcon fill="#FFFFFF" size="4" />
      </div>

      {attachments.map((attachment, index) => {
        const isImage = attachment.mime_type.startsWith('image/');
        const fileExtension = attachment.file_name.split('.').pop()?.toUpperCase() || 'FILE';

        return (
          <div key={index} className="relative w-[66px] flex flex-col items-center">
            <div
              onClick={() => removeFile(index)}
              className="absolute top-[-4px] right-[-4px] bg-[#8654CC] p-1 rounded-full cursor-pointer"
            >
              <AdminCloseIcon fill="#FFFFFF" size="4" />
            </div>

            <div className="border border-[#8298AD] rounded-[6px] flex items-center justify-center overflow-hidden mb-1">
              {isImage ? (
                <img
                  src={attachment.file_data}
                  alt={attachment.file_name}
                  className="object-cover w-[66px] h-[66px]"
                />
              ) : (
                <div className="text-purple-600 font-semibold text-sm w-[66px] h-[66px]">
                  {fileExtension}
                </div>
              )}
            </div>

            <div className="text-xs text-center text-gray-700 truncate w-full">
              {attachment.file_name}
            </div>
          </div>
        );
      })}
    </div>
  );
};
