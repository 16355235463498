import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from './useRedux';
import {
  selectChatMessages,
  selectUserTicketInfo,
  selectChatMessagesLoading,
} from 'src/store/support';
import { fetchChatMessages, fetchChatTicketInfo } from 'src/store/support';
import { connectToUserChat } from 'src/store/support/api';
import { setChatMessagesFromSockets } from 'src/store/support/model';

interface UseChatSocketProps {
  ticket_id?: string;
  user_id?: number;
}

export const useUserChatSocket = ({ ticket_id, user_id }: UseChatSocketProps) => {
  const dispatch = useAppDispatch();
  const chatMessages = useSelector(selectChatMessages);
  const chatMessagesLoading = useSelector(selectChatMessagesLoading);
  const ticketInfo = useSelector(selectUserTicketInfo);
  const socketRef = useRef<WebSocket | null>(null);
  const currentTicketRef = useRef<string | undefined>(ticket_id);
  const currentUserRef = useRef<number | undefined>(user_id);

  useEffect(() => {
    if (!ticket_id || !user_id) return;
    if (
      socketRef.current &&
      currentTicketRef.current === ticket_id &&
      currentUserRef.current === user_id
    ) {
      return;
    }

    currentTicketRef.current = ticket_id;
    currentUserRef.current = user_id;

    Promise.all([
      dispatch(fetchChatMessages(ticket_id)).unwrap(),
      dispatch(fetchChatTicketInfo(ticket_id)).unwrap(),
    ])
      .then(() => {
        if (!socketRef.current) {
          const socket = connectToUserChat({ ticket_id, user_id }, message => {
            dispatch(setChatMessagesFromSockets(message));
          });

          socketRef.current = socket;
        }
      })
      .catch(error => console.error('Ошибка загрузки чата или информации о тикете:', error));

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [ticket_id, user_id, dispatch]);

  return { chatMessages, ticketInfo, chatMessagesLoading };
};
