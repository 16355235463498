import { Dispatch, FC, SetStateAction, useMemo, useRef, useState } from 'react';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { Status, Ticket, Topics } from 'src/store/support/model/types';
import cn from 'classnames';
import { FilterIcon } from 'src/assets/icons/kit/Filter';
import { formatRussianDate } from 'src/utils';
import { CheckBox } from '../CheckBox';
import { AdminCloseIcon } from 'src/assets/icons/kit/AdminClose';
import { CalendarIcon } from 'src/assets/icons/kit/Calendar';
import { CalendarPicker } from '../kit/CalendarPicker';

interface SupportUsersListProps {
  loadingIncidents: boolean;
  incidents: Ticket[];
  selectedIncident: Ticket | null;
  search: string;
  setSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ticketStatuses: Status[];
  handleSelectIncident: (incident: Ticket | null) => void;
  selectedStatuses: string[];
  setSelectedStatuses: Dispatch<string[]>;
  selectedTopics: string[];
  setSelectedTopics: Dispatch<string[]>;
  topics: Topics[];
}

export const SupportUsersList: FC<SupportUsersListProps> = ({
  loadingIncidents,
  incidents,
  selectedIncident,
  handleSelectIncident,
  search,
  setSearch,
  ticketStatuses,
  selectedStatuses,
  setSelectedStatuses,
  selectedTopics,
  setSelectedTopics,
  topics,
}) => {
  const { width } = useWindowDimensions();
  const [isShowFilters, setIsShowFilters] = useState(false);

  return (
    <div className="h-full t-lg:min-w-[24rem] t-lg:w-96 w-full t-lg:bg-white relative z-10">
      <aside className="ml-4 mr-2 flex w-[100%] max-w-[396px] flex-col pr-2">
        <div className="p-4">
          <div className="mb-3">
            <input
              value={search}
              onChange={e => setSearch(e)}
              type="text"
              placeholder="Поиск по ID"
              className="w-full rounded-lg border border-[#E5E5E5] h-[50px] bg-[#F5F6F8] px-3 py-2 text-sm text-gray-700 outline-none placeholder:text-gray-400 focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
            />
          </div>
          {!isShowFilters && (
            <div
              onClick={() => setIsShowFilters(!isShowFilters)}
              className="mb-4 flex h-9 w-full cursor-pointer items-center justify-center gap-1 rounded-full bg-[#F5F6F8] px-4 py-1 text-[14px] text-purple-600 hover:bg-gray-100"
            >
              Фильтры
              <div className="relative">
                <FilterIcon />
                {(!!selectedStatuses.length || !!selectedTopics.length) && (
                  <span
                    className="
                      absolute top-[-4px] right-[-4px]
                      flex items-center justify-center
                      h-[11px] w-[11px]
                      rounded-full bg-red-500
                      text-[7px]
                      text-[#FFFF]
                      leading-none
                    "
                  >
                    {selectedStatuses.length + selectedTopics.length}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        {isShowFilters ? (
          <FilterPanel
            onClose={() => setIsShowFilters(false)}
            ticketStatuses={ticketStatuses}
            selectedStatuses={selectedStatuses}
            setSelectedStatuses={setSelectedStatuses}
            selectedTopics={selectedTopics}
            setSelectedTopics={setSelectedTopics}
            topics={topics}
          />
        ) : (
          <>
            {!!incidents.length && (
              <TicketList
                tickets={incidents}
                selectedTicket={selectedIncident}
                onSelect={handleSelectIncident}
                ticketStatuses={ticketStatuses}
              />
            )}
            {!loadingIncidents && !incidents.length ? (
              <div className="text-center text-white-grey">У вас нет пользователей в списке.</div>
            ) : null}
          </>
        )}
      </aside>

      <div className="w-full overflow-auto t-lg:px-8 px-4">
        {/* {loadingIncidents ? <Loader classNames="mx-auto my-20 w-1/2 t-lg:w-full" /> : null} */}

        {!loadingIncidents && incidents.length && !incidents.length && search ? (
          <div className="my-20 text-center text-xl font-gilroy-500">
            По вашему запросу ничего не найдено
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const FilterPanel: React.FC<{
  onClose: () => void;
  ticketStatuses: Status[];
  selectedStatuses: string[];
  setSelectedStatuses: Dispatch<SetStateAction<string[]>>;
  selectedTopics: string[];
  setSelectedTopics: Dispatch<SetStateAction<string[]>>;
  topics: Topics[];
}> = ({
  onClose,
  ticketStatuses,
  selectedStatuses,
  setSelectedStatuses,
  selectedTopics,
  setSelectedTopics,
  topics,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const toggleTopic = (topic: string) => {
    setSelectedTopics(prev => {
      if (prev.includes(topic)) {
        return prev.filter(s => s !== topic);
      }
      return [...prev, topic];
    });
  };

  const toggleStatus = (status: string) => {
    setSelectedStatuses(prev => {
      if (prev.includes(status)) {
        return prev.filter(s => s !== status);
      }
      return [...prev, status];
    });
  };

  const handleOpenCalendar = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleCloseCalendar = () => {
    setIsCalendarOpen(false);
  };

  return (
    <div className="rounded-3xl border border-[#EAEAEA] p-[20px]">
      <div className="relative h-full w-full">
        <div className="mb-4 flex items-center justify-between pl-[18px] pr-[12px]">
          <span className="text-sm font-semibold text-purple-600">Фильтры</span>
          <div onClick={onClose} className="cursor-pointer">
            <AdminCloseIcon />
          </div>
        </div>

        <div
          className="mb-4 rounded-2xl border border-[#EAEAEA] bg-white p-4 cursor-pointer"
          onClick={handleOpenCalendar}
        >
          <div className="flex items-center justify-between">
            <span className="text-sm font-semibold text-gray-700">Выбрать дату</span>
            <button className="text-gray-400 transition-colors hover:text-gray-600">
              <CalendarIcon />
            </button>
          </div>

          {isCalendarOpen && (
            <div className="absolute left-0 mt-[-16px] z-10">
              <CalendarPicker
                isOpen={isCalendarOpen}
                onRequestClose={handleCloseCalendar}
                selectedDate={selectedDate}
                onChange={date => {
                  setSelectedDate(date);
                }}
              />
            </div>
          )}
        </div>

        <div className="rounded-2xl border border-[#EAEAEA] bg-white p-4 mb-4">
          <div className="mb-3 text-sm font-semibold text-gray-800">Статус</div>
          <div className="max-h-[177px] overflow-auto">
            {ticketStatuses?.map(({ id: statusId, title, color }) => {
              const isActive = selectedStatuses.includes(statusId);
              return (
                <div
                  key={statusId}
                  className="mb-2 flex items-center gap-2 cursor-pointer"
                  onClick={() => toggleStatus(statusId)}
                >
                  <CheckBox
                    isActive={isActive}
                    size="m"
                    activeBorder="primary"
                    classNames={cn(
                      'cursor-pointer select-none flex items-center justify-center border rounded-md w-[20px] h-[20px]',
                      isActive
                        ? 'bg-purple-500 border-purple-500'
                        : 'bg-[#FFFFFF] border-purple-500',
                    )}
                  />
                  <span
                    className={`h-[9px] w-[9px] rounded-full`}
                    style={{ backgroundColor: color }}
                  />
                  <span className="mr-2 text-sm text-gray-700">{title}</span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="rounded-2xl border border-[#EAEAEA] bg-white p-4">
          <div className="mb-3 text-sm font-semibold text-gray-800">Проблема</div>
          <div className="max-h-[177px] overflow-auto">
            {topics?.map(({ id: topicId, title }) => {
              const isActive = selectedTopics.includes(topicId);
              return (
                <div
                  key={topicId}
                  className="mb-2 flex items-center gap-2 cursor-pointer"
                  onClick={() => toggleTopic(topicId)}
                >
                  <CheckBox
                    isActive={isActive}
                    size="m"
                    activeBorder="primary"
                    classNames={cn(
                      'cursor-pointer select-none flex items-center justify-center border rounded-md w-[20px] h-[20px]',
                      isActive
                        ? 'bg-purple-500 border-purple-500'
                        : 'bg-[#FFFFFF] border-purple-500',
                    )}
                  />
                  <span className="mr-2 text-sm text-gray-700">{title}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const TicketList: React.FC<{
  tickets: Ticket[];
  selectedTicket: Ticket | null;
  onSelect: (id: Ticket) => void;
  ticketStatuses: Status[];
}> = ({ tickets, selectedTicket, onSelect, ticketStatuses }) => {
  return (
    <div className="overflow-auto pb-4 h-[calc(100vh-64px-146px)]">
      {tickets.map(ticket => {
        const statusColor = ticketStatuses.find(item => item.id === ticket.status)?.color;

        return (
          <TicketItem
            key={ticket.id}
            ticket={ticket}
            statusColor={statusColor}
            selected={selectedTicket?.id === ticket.id}
            onSelect={() => onSelect(ticket)}
          />
        );
      })}
    </div>
  );
};

const TicketItem: React.FC<{
  ticket: Ticket;
  selected: boolean;
  onSelect: () => void;
  statusColor?: string;
}> = ({ ticket, selected, onSelect, statusColor }) => {
  return (
    <div
      onClick={onSelect}
      className={cn(
        'mb-1 flex cursor-pointer items-center rounded-lg p-3 transition-colors',
        selected ? 'bg-purple-100' : 'hover:bg-gray-100',
      )}
    >
      <span
        className={cn('mr-3 h-[12px] w-[12px] rounded-full')}
        style={{ backgroundColor: statusColor || '#8000FF' }}
      />
      <div className="flex flex-1 flex-col">
        <span
          className="text-[20px] font-medium"
          style={{
            display: 'inline-block',
            maxWidth: '240px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {ticket.userId} - {ticket.id}
        </span>
        <span className="text-[14px] text-gray-500">{ticket.title}</span>
      </div>
      <div className="flex flex-col items-end">
        <span className="text-[10px] text-gray-400">
          {formatRussianDate({ isoDate: ticket.updatedAt })}
        </span>
        {ticket.unreadMessagesCount ? (
          <div className="mt-2 flex h-[13px] w-[13px] items-center justify-center rounded-full bg-purple-500 text-[7px] text-white">
            {ticket.unreadMessagesCount}
          </div>
        ) : (
          <div className="h-[20px]"></div>
        )}
      </div>
    </div>
  );
};
