import { Dispatch, useState } from 'react';
import { SortingIcon } from 'src/assets/icons/kit/Sorting';
import { WindowIcon } from 'src/assets/icons/kit/Window';
import { OrderLog } from 'src/store/support/model/types';

interface TableProps {
  data: OrderLog[];
  onPayloadClick: Dispatch<any>;
}

export const LogTable = ({ data, onPayloadClick }: TableProps) => {
  const [sortKey, setSortKey] = useState<keyof OrderLog | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const headers = [
    { label: 'Наименование события', key: 'message' },
    { label: 'Имя пользователя', key: 'user' },
    { label: 'Тип события', key: 'type' },
    { label: 'Дата события', key: 'createdAt' },
    { label: 'Статус события', key: 'orderId' },
    { label: 'Payload', key: 'payload' },
    { label: 'Status', key: 'status' },
  ];

  const sortedData = [...data].sort((a, b) => {
    if (!sortKey || !sortOrder) return 0;
    return sortOrder === 'asc'
      ? String(a[sortKey]).localeCompare(String(b[sortKey]))
      : String(b[sortKey]).localeCompare(String(a[sortKey]));
  });

  const paginatedData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const toggleSort = (key: keyof OrderLog) => {
    if (sortKey === key) {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else {
        setSortKey(null);
        setSortOrder(null);
      }
    } else {
      setSortKey(key);
      setSortOrder('asc');
    }
  };

  return (
    <div>
      <table className="w-full border-collapse table-auto">
        <thead className="bg-[#F6F0FF40]">
          <tr className="border-b">
            {headers.map(({ label, key }) => (
              <th
                key={key}
                onClick={() => toggleSort(key as keyof OrderLog)}
                className="cursor-pointer text-left px-6 py-4"
              >
                <div className="flex items-center gap-1">
                  <div className="text-[14px] whitespace-nowrap overflow-hidden text-ellipsis">
                    {label}
                  </div>
                  <SortingIcon sortOrder={sortKey === key ? sortOrder : null} />
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(log => (
            <tr key={log.id} className="border-b">
              {headers.map(({ key }) => (
                <td key={key} className="px-6 py-2 whitespace-nowrap overflow-hidden text-ellipsis">
                  <div
                    className="flex items-center gap-1"
                    onClick={() => {
                      if (key === 'payload' && log[key] === 'Открыть') {
                        onPayloadClick(log);
                      }
                    }}
                    style={log[key] === 'Открыть' ? { cursor: 'pointer' } : {}}
                  >
                    {log[key] ?? '-'}
                    {log[key] === 'Открыть' && <WindowIcon />}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex align-start">
        <div className="flex justify-center mt-4 gap-[5px] h-[24px]">
          <button
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className="h-[24px] w-[24px] text-[24px] disabled:opacity-50 flex flex-col justify-center items-center font-thin pb-[2px]"
          >
            «
          </button>
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="h-[24px] w-[24px] text-[24px] disabled:opacity-50 flex flex-col justify-center items-center font-thin pb-[2px]"
          >
            ‹
          </button>

          {[...Array(totalPages).keys()].map(page => (
            <button
              key={page}
              onClick={() => setCurrentPage(page + 1)}
              className={`h-[24px] w-[24px] rounded-[4px] font-semibold text-[13px] ${
                currentPage === page + 1 ? 'bg-purple-500 text-white' : ''
              }`}
            >
              {page + 1}
            </button>
          ))}

          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="h-[24px] w-[24px] text-[24px] disabled:opacity-50 flex flex-col justify-center items-center font-thin pb-[2px]"
          >
            ›
          </button>
          <button
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
            className="h-[24px] w-[24px] text-[24px] disabled:opacity-50 flex flex-col justify-center items-center font-thin pb-[2px]"
          >
            »
          </button>
        </div>
      </div>
    </div>
  );
};
