import { UserMessage } from 'src/store/support/model/types';
import { handleDownload } from 'src/utils';
import cn from 'classnames';

interface DefaultMessageTextBlockProps {
  message: UserMessage;
  bubbleColor: string;
  isUser: boolean;
}

export const DefaultMessageTextBlock: React.FC<DefaultMessageTextBlockProps> = ({
  message,
  bubbleColor,
  isUser,
}) => {
  return (
    <div
      className={cn(
        'rounded-xl px-4 py-3 text-sm text-gray-800 gap-4',
        bubbleColor,
        isUser
          ? 'rounded-bl-xl rounded-br-xl rounded-tr-xl'
          : 'rounded-bl-xl rounded-br-xl rounded-tl-xl',
      )}
    >
      {message.text}
      {message.files_info?.length && (
        <div className="flex gap-2">
          {message.files_info?.map((file, index) => {
            const isImage = file.fileType.startsWith('image/');
            const fileExtension = file.fileName.split('.').pop()?.toUpperCase() || 'FILE';
            const { fileName, fileType, downloadUrl } = file;

            return (
              <div
                key={index}
                onClick={() => handleDownload({ fileName, fileType, downloadUrl })}
                className="relative w-[66px] flex flex-col items-center cursor-pointer"
              >
                <div className="border border-[#8298AD] rounded-[6px] flex items-center justify-center overflow-hidden mb-1">
                  {isImage ? (
                    <img
                      src={downloadUrl}
                      alt={fileName}
                      className="object-cover w-[66px] h-[66px]"
                    />
                  ) : (
                    <div className="text-purple-600 font-semibold text-sm w-[66px] h-[66px]">
                      {fileExtension}
                    </div>
                  )}
                </div>

                <div className="text-xs text-center text-gray-700 truncate w-full">{fileName}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
