export const IdIcon = className => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0_2635_50067)">
        <path
          d="M3 12C3 7.757 3 5.636 4.318 4.318C5.636 3 7.758 3 12 3C16.242 3 18.364 3 19.682 4.318C21 5.636 21 7.758 21 12C21 16.242 21 18.364 19.682 19.682C18.364 21 16.242 21 12 21C7.758 21 5.636 21 4.318 19.682C3 18.364 3 16.242 3 12Z"
          stroke="#7B7B7B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.842 11.0539V14.8439C14.842 15.8879 14.352 15.7899 13.422 15.7899C12.7939 15.7899 12.1916 15.5405 11.7475 15.0964C11.3034 14.6523 11.054 14.05 11.054 13.4219C11.054 12.7939 11.3034 12.1916 11.7475 11.7475C12.1916 11.3034 12.7939 11.0539 13.422 11.0539H14.842ZM14.842 11.0539V8.21094"
          stroke="#7B7B7B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.21094 8.21094V15.7889"
          stroke="#7B7B7B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2635_50067">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
