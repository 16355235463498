interface JsonViewerProps {
  data: object;
}

export const JsonViewer = ({ data }: JsonViewerProps) => {
  return (
    <div className="bg-white p-4">
      <pre className="whitespace-pre-wrap break-words text-sm text-gray-800">
        {JSON.stringify(data, null, 2)}
      </pre>
    </div>
  );
};
