import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import {
  Order,
  OrderLog,
  Status,
  Ticket,
  UpdateTicketPayload,
  UserMessage,
} from 'src/store/support/model/types';
import { CalendarAltIcon } from 'src/assets/icons/kit/CalendarAlt';
import { IdIcon } from 'src/assets/icons/kit/Id';
import { ArrowUpIcon } from 'src/assets/icons/kit/ArrowUp';
import { formatRussianDate } from 'src/utils';
import { Dropdown } from '../kit/Dropdown';
import { UserInfoModal } from '../UserInfoModal';
import { OrderLogsModal } from '../OrderLogsModal';

interface FirstMessageTextBlockProps {
  bubbleColor: string;
  message: UserMessage;
  ticketStatuses: Status[];
  selectedIncident: Ticket;
  orderInfo: Order | null;
  onChangeTicketStatus: (data: UpdateTicketPayload) => void;
  orderLogs: OrderLog[];
  onLogPayloadClick: (payload) => void;
  isLogsModalOpen: boolean;
  setIsLogsModalOpen: Dispatch<boolean>;
}

export const FirstMessageTextBlock: React.FC<FirstMessageTextBlockProps> = ({
  bubbleColor,
  message,
  ticketStatuses,
  selectedIncident,
  onChangeTicketStatus,
  orderInfo,
  orderLogs,
  onLogPayloadClick,
  isLogsModalOpen,
  setIsLogsModalOpen,
}) => {
  const [selectedStatus, setSelectedStatus] = useState<Status | null>(ticketStatuses[0] || null);
  const [isStatusSelectOpen, setIsStatusSelectOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [isUserInfoModalOpen, setIsUserInfoModalOpen] = useState(false);

  const logsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (logsContainerRef.current && !logsContainerRef.current.contains(event.target as Node)) {
        setIsPopupOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelectStatus = (status: Status) => {
    onChangeTicketStatus({
      ...selectedIncident,
      status: status.id,
    });
    setSelectedStatus(status);
    setIsStatusSelectOpen(false);
  };

  const handleLogsOptionClick = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const status = ticketStatuses.find(item => selectedIncident.status === item.id);

    if (status) setSelectedStatus(status);
  }, [selectedIncident, ticketStatuses]);

  return (
    <div>
      <div
        className={cn(
          'rounded-xl px-4 py-3 text-sm text-gray-800',
          bubbleColor,
          'rounded-bl-xl rounded-br-xl rounded-tr-xl',
        )}
      >
        <div className="flex items-center justify-between mb-2 gap-[24px]">
          <div className="flex gap-2 items-center">
            <IdIcon />
            <span className="text-[20px] font-medium whitespace-nowrap">
              {selectedIncident?.userId} - {selectedIncident?.orderId}
            </span>
          </div>

          <div className="flex gap-2 items-center">
            <CalendarAltIcon />
            <span className="text-[14px] whitespace-nowrap">
              {formatRussianDate({
                isoDate: selectedIncident.createdAt,
                isShowTime: true,
                isNumeric: true,
              })}
            </span>
          </div>

          <Dropdown
            isOpen={isStatusSelectOpen}
            onClose={() => setIsStatusSelectOpen(false)}
            toggle={() => setIsStatusSelectOpen(!isStatusSelectOpen)}
            containerClassName="relative"
            optionsWrapperClassName="right-0 mt-2"
            renderTrigger={() => (
              <div className="w-[179px] h-[44px] flex items-center justify-between bg-[#FFFFFF80] cursor-pointer border border-[#CFCFE0] bg-white rounded-[8px] py-[6px] px-[12px] text-[14px] text-[#5B5B6D] relative">
                {selectedStatus && (
                  <div className="gap-2 flex items-center">
                    <span
                      className="inline-block rounded-full"
                      style={{
                        width: '9px',
                        height: '9px',
                        backgroundColor: selectedStatus.color,
                      }}
                    />
                    <span className="text-[18px] whitespace-nowrap">{selectedStatus.title}</span>
                  </div>
                )}
                <svg
                  className="w-4 h-4 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                >
                  <path d="M6 9l6 6 6-6" />
                </svg>
              </div>
            )}
            renderOptions={() => (
              <div className="w-[140px] border border-[#CFCFE0] bg-[#FFF] rounded-[8px] shadow-lg overflow-hidden">
                {ticketStatuses.map((item, index) => (
                  <div
                    key={item.id}
                    className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 cursor-pointer"
                    style={{
                      borderBottom: index !== ticketStatuses.length - 1 ? '1px solid #E3EEF8' : '',
                    }}
                    onClick={() => handleSelectStatus(item)}
                  >
                    <span
                      className="h-[9px] w-[9px] inline-block rounded-full"
                      style={{ backgroundColor: item.color }}
                    />
                    <span className="text-[14px]" style={{ color: item.color }}>
                      {item.title}
                    </span>
                  </div>
                ))}
              </div>
            )}
          />
        </div>

        <div className="flex flex-col">
          {message.text}
          {message.files_info?.length ? (
            <div className="flex gap-2 mt-2">
              {message.files_info.map((file, index) => {
                const isImage = file.fileType.startsWith('image/');
                const fileExtension = file.fileName.split('.').pop()?.toUpperCase() || 'FILE';
                return (
                  <a
                    key={index}
                    href={file.downloadUrl}
                    className="relative w-[66px] flex flex-col items-center cursor-pointer"
                  >
                    <div className="border border-[#8298AD] rounded-[6px] flex items-center justify-center overflow-hidden mb-1 w-[66px] h-[66px]">
                      {isImage ? (
                        <img
                          alt={file.fileName}
                          src={file.downloadUrl}
                          className="object-cover w-full h-full"
                        />
                      ) : (
                        <div className="text-purple-600 font-semibold text-sm w-full h-full flex items-center justify-center">
                          {fileExtension}
                        </div>
                      )}
                    </div>
                    <div className="text-xs text-center text-gray-700 truncate w-full">
                      {file.fileName}
                    </div>
                  </a>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex items-center gap-2 h-[53px] mt-1">
        <div className="flex h-full gap-[2px] w-[50%] relative">
          <div
            onClick={() => setIsLogsModalOpen(true)}
            className="text-[20px] text-white h-full w-full bg-violet-200 rounded-l-[4px] flex flex-col justify-center items-center cursor-pointer"
          >
            Раскрыть логи
          </div>

          <div
            ref={logsContainerRef}
            className=" h-full bg-violet-200 rounded-r-[4px] min-w-[53px] flex flex-col justify-center items-center cursor-pointer relative
            "
            onClick={() => setIsPopupOpen(!isPopupOpen)}
          >
            <ArrowUpIcon />

            {isPopupOpen && (
              <div
                className="absolute bottom-full right-0 mb-2 bg-white border border-gray-200 rounded-[8px] shadow-lg text-gray-700 flex flex-col w-[209px]
                "
              >
                <div
                  className="text-[14px] cursor-pointer px-2 py-2 border-b-[1px] border-[#E3EEF8]"
                  onClick={() => setIsLogsModalOpen(true)}
                >
                  Раскрыть логи
                </div>
                <div
                  className="text-[14px] cursor-pointer px-2 py-2 border-b-[1px] border-[#E3EEF8]"
                  onClick={() => setIsUserInfoModalOpen(true)}
                >
                  Раскрыть детали
                </div>
                <div
                  className="text-[14px] cursor-pointer px-2 py-2"
                  onClick={() => setIsUserInfoModalOpen(true)}
                >
                  Раскрыть личные данные
                </div>
              </div>
            )}
          </div>
        </div>

        <UserInfoModal
          isUserInfoModalOpen={isUserInfoModalOpen}
          setIsUserInfoModalOpen={setIsUserInfoModalOpen}
          orderInfo={orderInfo}
        />

        <OrderLogsModal
          setIsLogsModalOpen={setIsLogsModalOpen}
          isLogsModalOpen={isLogsModalOpen}
          orderLogs={orderLogs}
          selectedIncident={selectedIncident}
          onLogPayloadClick={onLogPayloadClick}
        />

        <div className="text-[20px] text-white h-full bg-violet-200 rounded-[4px] w-[50%] flex flex-col justify-center items-center cursor-pointer">
          Эскалировать
        </div>
      </div>
    </div>
  );
};
