import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShopsState } from './types';
import { fetchPresets, fetchShops } from './actions';
import { PresetType, ShopType } from 'src/config/types';

const initialState: ShopsState = {
  shops: [],
  selectedShop: null,
  isLoadingShops: true,
  presets: [],
  isLoadingPresets: true,
  error: '',
};

const shopsSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {
    saveShops: (state, action: PayloadAction<ShopType[]>) => {
      state.isLoadingShops = false;

      if (action.payload.length) {
        state.shops = action.payload;
        state.selectedShop = action.payload[0];
      }
    },
    setShops: (state, action: PayloadAction<ShopType[]>) => {
      state.shops = action.payload;
    },
    selectShop: (state, action: PayloadAction<ShopType>) => {
      state.selectedShop = action.payload;
    },
  },
  extraReducers: {
    [fetchShops.pending.type]: state => {
      state.isLoadingShops = true;
      state.shops = [];
    },
    [fetchShops.fulfilled.type]: (state, action: PayloadAction<ShopType[]>) => {
      state.isLoadingShops = false;
      state.error = '';

      if (action.payload.length) {
        state.shops = action.payload;
        state.selectedShop = action.payload[0];
      }
    },
    [fetchShops.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingShops = false;
      state.error = action.payload;
    },
    [fetchPresets.pending.type]: state => {
      state.isLoadingPresets = true;
      state.presets = [];
    },
    [fetchPresets.fulfilled.type]: (state, action: PayloadAction<PresetType[]>) => {
      state.isLoadingPresets = false;
      state.presets = action.payload;
      state.error = '';
    },
    [fetchPresets.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingPresets = false;
      state.error = action.payload;
    },
  },
});

export const { saveShops, setShops, selectShop } = shopsSlice.actions;

const reducer = shopsSlice.reducer;
export default reducer;
