interface SortingIconProps {
  sortOrder: 'asc' | 'desc' | null;
}

export const SortingIcon = ({ sortOrder }: SortingIconProps) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3095_18641)">
        <path
          d="M6.33325 2.5V17.5"
          stroke={sortOrder === 'asc' ? '#000' : '#DFE0E3'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.83325 5L6.33325 2.5L3.83325 5"
          stroke={sortOrder === 'asc' ? '#000' : '#DFE0E3'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.1667 15L14.6667 17.5L12.1667 15"
          stroke={sortOrder === 'desc' ? '#000' : '#DFE0E3'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6667 17.5V2.5"
          stroke={sortOrder === 'desc' ? '#000' : '#DFE0E3'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3095_18641">
          <rect width="20" height="20" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
