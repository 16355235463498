import { FC, ReactNode, useMemo } from 'react';
import { ControlledInput } from 'src/components/kit/ControlledInput';
import { setGlobalSearch } from 'src/store/common';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { AppToolTip } from 'src/components/AppToolTip';
import { Link } from 'react-router-dom';

import cn from 'classnames';
import { ArrowLeft } from 'src/assets/icons/kit/ArrowLeft';

interface DashboardContainerProps {
  exel?: ReactNode;
  search?: boolean;
  searchElement?: ReactNode;
  underSearchElement?: ReactNode;
  title?: string | ReactNode;
  description?: string | ReactNode;
  children?: ReactNode;
  noToolTip?: boolean;
  classNames?: string;
}

export const DashboardContainer: FC<DashboardContainerProps> = ({
  exel,
  search,
  searchElement,
  underSearchElement,
  title,
  description,
  children,
  noToolTip,
  classNames,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    shops: { selectedShop },
    groups: { groups },
    common: { globalSearch },
  } = useAppSelector(state => state);
  const { shopId, groupId } = useParams();

  const handleChangeSearch = (search: string) => dispatch(setGlobalSearch(search));

  const onNavigateBack = () => {
    navigate(-1);
  };

  const group = useMemo(
    () => groups.find(group => group.id.toString() === groupId),
    [groups, groupId],
  );

  const getLink = (url: string, name: string, colored?: boolean) => (
    <>
      <Link to={url}>
        <AppToolTip
          classNames={cn(
            { 'text-blue-100': Boolean(colored) },
            'truncate max-w-[8rem] hover:text-blue-100',
          )}
          id={`dashboard-container-link-${url}-tooltip`}
          text={name}
        />
      </Link>
    </>
  );

  return (
    <div className="p-4 t-lg:p-5 pt-0 cursor-default">
      {shopId || groupId ? (
        <div className="flex gap-2 mb-2.5 text-grey-100 font-gilroy-400 text-xs transition-full">
          {shopId ? (
            <>
              {getLink('/dashboard/shops', 'Мои магазины')}
              {'/'}
              {getLink(`/dashboard/shops/${shopId}`, selectedShop?.name || '', !groupId)}
            </>
          ) : null}
          {groupId ? (
            <>
              {'/'}
              {getLink(`/dashboard/shops/${shopId}/${groupId}`, group?.name || '', true)}
            </>
          ) : null}
        </div>
      ) : null}
      <div className={cn({ 'mb-2.5': Boolean(description) }, 'flex gap-3 items-center')}>
        <ArrowLeft onClick={onNavigateBack} />
        {noToolTip ? (
          <div className="font-gilroy-500 text-xl t-lg:text-3xl line-clamp-2">{title}</div>
        ) : (
          <AppToolTip
            classNames={cn('font-gilroy-500 text-xl t-lg:text-3xl line-clamp-2')}
            id="dashboard-container-title-tooltip"
            text={title?.toString() || ''}
          />
        )}
      </div>

      {description ? (
        <div className="flex flex-col t-sm:flex-row gap-2.5 w-full justify-between">
          {noToolTip ? (
            <div className="font-gilroy-400 text-xs t-lg:text-base text-grey-100">
              {description}
            </div>
          ) : (
            <AppToolTip
              classNames="font-gilroy-400 text-xs t-lg:text-base text-grey-100"
              id="dashboard-container-description-tooltip"
              text={description?.toString() || ''}
            />
          )}

          {exel ? exel : null}
        </div>
      ) : null}

      {search ? (
        <div className="flex flex-col gap-2.5 mt-2.5" id="global-search">
          <div className="flex flex-col w-full gap-2.5 t-lg:flex-row items-center justify-between">
            <ControlledInput
              classNames="w-full t-lg:w-[38rem] d-xs:w-[50rem]"
              onChange={handleChangeSearch}
              placeholder="Поиск"
              value={globalSearch}
            />
            {searchElement && searchElement}
          </div>
          {underSearchElement && underSearchElement}
        </div>
      ) : null}

      <div className={cn('py-8 w-full', classNames)}>{children}</div>
    </div>
  );
};
