import { shortMonthsNames } from 'src/config/data';
import {
  StatItemType,
  StatItemTypeReq,
  StatType,
  StatTypeReq,
  SupportProductType,
} from 'src/config/types';

export const debounce = (fn: (...args: any[]) => void, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const noDots = str => (str ? str?.replaceAll('.', ',') : '');
export const noMark = str => (str ? str?.replaceAll(',', '.') : '');

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'kB', 'mB', 'gB', 'tB', 'pB', 'eB', 'zB', 'yB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const numberWithSpaces = (number: string | number): string => {
  const parts = number.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return parts.join('.');
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const statImagesToB64 = (parameterValues: StatItemType[]) =>
  new Promise<StatItemTypeReq[]>(async (resolve, reject) => {
    try {
      const temp: StatItemTypeReq[] = [];

      for (const value of parameterValues) {
        temp.push({
          id: value.valueId,
          name: value.valueName || '',
          flag: value.flag || '',
          image: value.image
            ? typeof value.image === 'string'
              ? value.image
              : ((await toBase64(value.image)) as string)
            : '',
        });
      }

      resolve(temp);
    } catch (e) {
      reject([] as StatItemTypeReq[]);
    }
  });

export const prepareProductStats = async (stats: StatType[], goodId: string) => {
  let params: StatTypeReq[] = [];

  for (const stat of stats) {
    params = [
      ...params,
      {
        name: stat.parameterName,
        flag: stat.flag || '',
        goodId: goodId?.toString(),
        id: stat.flag === 'insert' ? '' : stat.parameterId,
        parameterValues: await statImagesToB64(stat.parameterValues),
      },
    ];
  }
  return params;
};

export const base64toFile = (dataUrl, filename) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const generateUUID = () => {
  let d = new Date().getTime();
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const declOfNum = (n: number, text_forms: string[]) => {
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return text_forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return text_forms[1];
  }
  if (n1 == 1) {
    return text_forms[0];
  }
  return text_forms[2];
};

export const getZero = (num: number) => (num < 10 ? '0' : '');

export const getDateToSupportChat = (dateStr: string) => {
  const date = new Date(dateStr);

  if (date.toDateString() === new Date().toDateString()) {
    return `${getZero(date.getHours())}${date.getHours()}:${getZero(
      date.getMinutes(),
    )}${date.getMinutes()}`;
  }

  return `${getZero(date.getDate())}${date.getDate()} ${shortMonthsNames[date.getMonth()]}`;
};

export const calculateOrderInfo = (orderGoods: SupportProductType[]) => {
  const res: { count: number; price: string } = { count: 0, price: '' };

  orderGoods.forEach(good => {
    res.count += good.amount;
    res.price = (+res.price + good.amount * noMark(good.newPrice)).toString();
  });

  res.price = numberWithSpaces(res.price);

  return res;
};

export const isEmpty = obj => {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const paramsToQuery = obj =>
  Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join('&');

export const scrollToErrors = () => {
  const errorsList = document.querySelectorAll('.hook-form-error');
  if (errorsList?.length) {
    errorsList[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const noGrid = color =>
  color?.includes('gradient') ? color : color?.replace('#', '') || '';

export const addGrid = color => (color?.includes('gradient') ? color : `#${color}`);

export const isMacOs = () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

export const formatRussianDate = ({
  isoDate,
  isYear,
  isShowTime,
  isNumeric,
}: {
  isoDate: string;
  isYear?: boolean;
  isShowTime?: boolean;
  isNumeric?: boolean;
}): string => {
  if (!isoDate) return '';

  const date = new Date(isoDate);

  if (isNumeric) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    if (isShowTime) {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    return `${day}.${month}.${year}`;
  }

  const months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];

  if (isShowTime) {
    return date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
  }

  if (isYear) {
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  const day = date.getDate();
  const month = months[date.getMonth()];

  return `${day} ${month}`;
};

export const handleDownload = async (file: {
  downloadUrl: string;
  fileName: string;
  fileType: string;
}) => {
  try {
    const response = await fetch(file.downloadUrl);
    if (!response.ok) {
      throw new Error(`Ошибка сети: ${response.status}`);
    }
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = downloadUrl;
    a.download = file.fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Ошибка при скачивании файла:', error);
  }
};
