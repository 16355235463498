import { FC, useState } from 'react';
import { ShopType } from 'src/config/types';
import { Button } from 'src/components/kit/Button';
import { Modal } from 'src/components/kit/Modal';
import { CreateShopForm } from 'src/components/Forms/CreateShopForm';
import { useAppDispatch } from 'src/hooks/useRedux';
import { DeleteModal } from 'src/components/Modals/DeleteModal';
import { fetchShops, postShopSwitcher } from 'src/store/shops/actions';
import { selectShop, setShops } from 'src/store/shops';
import { AppToolTip } from 'src/components/AppToolTip';
import ShopService from 'src/services/ShopService';

import { ReactComponent as EditIcon } from 'src/assets/icons/kit/edit.svg';
import { ReactComponent as LogoIcon } from 'src/assets/icons/logo.svg';

import cn from 'classnames';
import { BasketIconButton } from 'src/components/IconButton/BasketIconButton';
import { Switch } from 'src/components/kit/Switch';

interface ShopCardProps {
  data: ShopType;
  shops: ShopType[];
  classNames?: string;
}

export const ShopCard: FC<ShopCardProps> = ({ data, shops, classNames }) => {
  const dispatch = useAppDispatch();

  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  const openAddModal = () => setIsOpenAddModal(true);
  const closeAddModal = () => setIsOpenAddModal(false);

  const openDeleteModal = () => setIsOpenDeleteModal(true);
  const closeDeleteModal = () => setIsOpenDeleteModal(false);

  const handleDeleteShop = async () => {
    await ShopService.deleteShop(data.id);
    await dispatch(fetchShops());
  };

  const onShopSwitcherChange = value => {
    dispatch(
      postShopSwitcher({
        ...data,
        isActive: !data.isActive,
      }),
    );

    const updatedShops = shops.map(shop =>
      shop.id === data.id ? { ...shop, isActive: !data.isActive } : shop,
    );

    dispatch(setShops(updatedShops));
  };

  const generateDeleteText = () => {
    const productsText = data.productsCount
      ? `Данный магазин сдержит ${data.productsCount} активных товаров.\n`
      : '';

    return `${productsText}Вы уверены что хотите деактивировать этот магазин ?`;
  };

  const handleChangeSelectedShop = () => {
    dispatch(selectShop(data));
  };

  return (
    <div
      className={cn(
        'bg-white p-4 t-sm:p-8 flex flex-col t-sm:flex-row t-sm:items-end rounded-4xl',
        'gap-6 justify-between relative',
        classNames,
      )}
    >
      <div className="flex items-center gap-2 t-lg:gap-4 w-full t-sm:w-auto">
        <div className="h-28 w-28 min-w-[7rem] rounded-full overflow-hidden">
          {data.image ? (
            <img src={data.image} alt="" className="w-full h-full object-cover" />
          ) : (
            <LogoIcon className="w-full h-full " />
          )}
        </div>
        <div
          className={
            'flex flex-col gap-[20px] w-1/2 m-md:w-2/3 t-sm:w-52 t-lg:w-[27rem] d-xs:w-[38rem] d-lg:w-[40rem]'
          }
        >
          <div className="flex flex-col gap-1.5">
            <AppToolTip
              classNames="font-gilroy-500 text-lg w-full truncate"
              id={`shop-card-name-${data.id}-tooltip`}
              text={data.name}
            />

            <AppToolTip
              classNames="font-gilroy-400 text-sm w-full text-grey-100 truncate"
              id={`shop-card-description-${data.id}-tooltip`}
              text={data.description}
            />
          </div>

          <div className="flex flex-col w-fit t-lg:flex-row gap-2 m-md:items-center">
            <Button
              variant="turquoise-light"
              size="small"
              classNames="w-fit py-[10px]"
              onClick={openAddModal}
            >
              Редактировать
              <EditIcon className="w-3 h-3 fill-turquoise-100" />
            </Button>

            {/*<Button*/}
            {/*  href={`https://${data.tgAddress}`}*/}
            {/*  newWindow*/}
            {/*  variant="green"*/}
            {/*  size="small"*/}
            {/*  classNames="w-fit"*/}
            {/*>*/}
            {/*  Перейти в бот*/}
            {/*  <TgIcon className="w-3 h-3 fill-white" />*/}
            {/*</Button>*/}
          </div>
        </div>
      </div>

      <div className="flex flex-col h-full gap-8">
        <div className="flex justify-end">
          <Switch
            name={data.id}
            isChecked={data.isActive}
            setValue={onShopSwitcherChange}
            variant="shop"
          />
        </div>
        <div className="w-full t-sm:w-fit flex flex-col gap-1 t-sm:flex-row t-lg:gap-5 items-center">
          <Button
            buttonVariant="link"
            variant="turquoise"
            classNames="w-full t-lg:w-fit"
            onClick={handleChangeSelectedShop}
            href={`/dashboard/shops/${data.id}`}
          >
            Каталог товаров
          </Button>
          <BasketIconButton onClick={openDeleteModal} image="basket" />
          {/*<Button buttonVariant="link" variant="turquoise-outline" classNames="w-full t-lg:w-fit">*/}
          {/*  Рассылки*/}
          {/*</Button>*/}
        </div>
      </div>

      {isOpenDeleteModal ? (
        <Modal isOpen={isOpenDeleteModal} onClose={closeDeleteModal}>
          <DeleteModal
            text={generateDeleteText()}
            handleDelete={handleDeleteShop}
            handleClose={closeDeleteModal}
            removeText="Подтвердить"
          />
        </Modal>
      ) : null}

      {isOpenAddModal ? (
        <Modal isOpen={isOpenAddModal} onClose={closeAddModal}>
          <CreateShopForm defaultValues={data} onSave={closeAddModal} />
        </Modal>
      ) : null}
    </div>
  );
};
