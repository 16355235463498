export const ChatCheckIcon = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2097 3.0839L4.25058 9.76829L1.79028 7.40512C1.67382 7.29326 1.48499 7.29326 1.3685 7.40512L1.08734 7.67518C0.970885 7.78704 0.970885 7.96842 1.08734 8.08031L4.0397 10.9161C4.15616 11.028 4.34499 11.028 4.46148 10.9161L11.9127 3.7591C12.0291 3.64724 12.0291 3.46587 11.9127 3.35398L11.6315 3.0839C11.515 2.97203 11.3262 2.97203 11.2097 3.0839Z"
        fill="#24B23E"
      />
      <path
        d="M15.2183 3.0839L8.33512 9.76829L7.78166 9.23095C7.66647 9.11909 7.4797 9.11909 7.36448 9.23095L7.08639 9.50101C6.9712 9.61287 6.9712 9.79425 7.08639 9.90613L8.12655 10.9161C8.24174 11.028 8.42851 11.028 8.54372 10.9161L15.9136 3.7591C16.0288 3.64724 16.0288 3.46587 15.9136 3.35398L15.6355 3.0839C15.5203 2.97203 15.3335 2.97203 15.2183 3.0839Z"
        fill="#24B23E"
      />
    </svg>
  );
};
