import { SetStateAction, useRef } from 'react';
import { Dispatch } from 'react';
import { AdminAttachIcon } from 'src/assets/icons/kit/AdminAttach';

interface Attachment {
  file_data: string;
  file_name: string;
  file_size: number;
  mime_type: string;
}

interface MessageInputProps {
  onSubmit: () => void;
  value: string;
  onChange: Dispatch<string>;
  onFilesChange: Dispatch<SetStateAction<Attachment[]>>;
}

export const MessageInput = ({ value, onSubmit, onChange, onFilesChange }: MessageInputProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleIconClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    const fileArray = Array.from(files);

    Promise.all(
      fileArray.map(
        file =>
          new Promise<Attachment>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              resolve({
                file_data: reader.result as string,
                file_name: file.name,
                file_size: file.size,
                mime_type: file.type,
              });
            };
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
          }),
      ),
    )
      .then(newAttachments => {
        onFilesChange(prev => [...prev, ...newAttachments]);
      })
      .catch(error => {
        console.error('Ошибка при чтении файлов', error);
      })
      .finally(() => {
        e.target.value = '';
      });
  };

  return (
    <div className="pt-3">
      <div className="relative flex items-center rounded-[10px] bg-[#F5F6F8] px-4 py-2">
        <div className="absolute left-3 cursor-pointer" onClick={handleIconClick}>
          <AdminAttachIcon />
        </div>
        <input
          onChange={e => onChange(e.target.value)}
          value={value}
          placeholder="Введите текст сообщения"
          className="flex-1 bg-transparent text-sm text-gray-700 pl-6 outline-none placeholder:text-gray-400"
        />
        <input
          type="file"
          multiple
          ref={fileInputRef}
          onChange={handleFileChange}
          className="hidden"
        />
        <button
          className="ml-2 flex h-8 w-8 items-center justify-center rounded-full bg-purple-500 hover:bg-purple-600"
          onClick={onSubmit}
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="h-4 w-4"
          >
            <line x1="22" y1="2" x2="11" y2="13" />
            <polygon points="22 2 15 22 11 13 2 9 22 2" />
          </svg>
        </button>
      </div>
    </div>
  );
};
