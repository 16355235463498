import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setTopics,
  setAppeals,
  setChatTicketInfo,
  setChatMessages,
  setChatMessagesLoading,
  setTicketStatuses,
  setOrderInfo,
  setOrderLogs,
} from './index';
import {
  getChatMessages,
  getCurrentChatTicketInfo,
  getOrderInfo,
  getOrderLogs,
  getTicketStatuses,
  getTicketTopics,
  getUserTickets,
  sendUserMessage,
  updateUserTicket,
} from '../api';
import { CreateMessagePayload, UpdateTicketPayload } from './types';

export const fetchTicketTopics = createAsyncThunk(
  'support/fetchTicketTopics',
  async (_, { dispatch }) => {
    try {
      const response = await getTicketTopics();
      dispatch(setTopics(response.data.data));
    } catch (error) {
      console.error('fetchTicketTopics error:', error);
      throw error;
    }
  },
);

export const fetchTicketStatuses = createAsyncThunk(
  'support/fetchTicketTopics',
  async (_, { dispatch }) => {
    try {
      const response = await getTicketStatuses();
      dispatch(setTicketStatuses(response.data.data));
    } catch (error) {
      console.error('fetchTicketTopics error:', error);
      throw error;
    }
  },
);

export const fetchOrderLogs = createAsyncThunk(
  'support/fetchTicketTopics',
  async (orderId: string, { dispatch }) => {
    try {
      const response = await getOrderLogs(orderId);
      dispatch(setOrderLogs(response.data.data));
    } catch (error) {
      console.error('fetchTicketTopics error:', error);
      throw error;
    }
  },
);

export const fetchSupportTickets = createAsyncThunk(
  'support/fetchUserTickets',
  async (data: { status?: string; user_id?: number }, { dispatch }) => {
    try {
      const response = await getUserTickets(data);
      dispatch(setAppeals(response.data.data));
    } catch (error) {
      console.error('fetchUserTickets error:', error);
      throw error;
    }
  },
);

export const fetchOrderInfo = createAsyncThunk(
  'support/fetchTicketTopics',
  async (id: string, { dispatch }) => {
    try {
      const response = await getOrderInfo(id);
      dispatch(setOrderInfo(response.data.data));
    } catch (error) {
      console.error('fetchTicketTopics error:', error);
      throw error;
    }
  },
);

export const postSupportMessage = createAsyncThunk(
  'support/postUserTicket',
  async (data: CreateMessagePayload) => {
    try {
      await sendUserMessage(data);
    } catch (error) {
      console.error('postUserTicket error:', error);
      throw error;
    }
  },
);

export const putUserTicket = createAsyncThunk(
  'support/putUserTicket',
  async (data: UpdateTicketPayload) => {
    try {
      await updateUserTicket(data);
    } catch (error) {
      console.error('postUserTicket error:', error);
      throw error;
    }
  },
);

export const fetchChatTicketInfo = createAsyncThunk(
  'support/postUserTicket',
  async (id: string, { dispatch }) => {
    try {
      const response = await getCurrentChatTicketInfo(id);
      dispatch(setChatTicketInfo(response.data.data));

      return response.data.data;
    } catch (error) {
      console.error('postUserTicket error:', error);
      throw error;
    }
  },
);

export const fetchChatMessages = createAsyncThunk(
  'support/postUserTicket',
  async (id: string, { dispatch }) => {
    try {
      dispatch(setChatMessagesLoading(true));
      const response = await getChatMessages(id);
      dispatch(setChatMessages(response.data.data));
    } catch (error) {
      console.error('postUserTicket error:', error);
      throw error;
    } finally {
      dispatch(setChatMessagesLoading(false));
    }
  },
);
