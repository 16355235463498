export const CalendarAltIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2635_50074)">
        <path
          d="M4 7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H18C18.5304 5 19.0391 5.21071 19.4142 5.58579C19.7893 5.96086 20 6.46957 20 7V19C20 19.5304 19.7893 20.0391 19.4142 20.4142C19.0391 20.7893 18.5304 21 18 21H6C5.46957 21 4.96086 20.7893 4.58579 20.4142C4.21071 20.0391 4 19.5304 4 19V7Z"
          stroke="#7B7B7B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M16 3V7" stroke="#7B7B7B" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8 3V7" stroke="#7B7B7B" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M4 11H20" stroke="#7B7B7B" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7 14H7.0156" stroke="#7B7B7B" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M10.01 14H10.016"
          stroke="#7B7B7B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.01 14H13.016"
          stroke="#7B7B7B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.0149 14H16.0209"
          stroke="#7B7B7B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.0149 17H13.0209"
          stroke="#7B7B7B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.01001 17H7.01651"
          stroke="#7B7B7B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.01 17H10.016"
          stroke="#7B7B7B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2635_50074">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
