import { CSSProperties, FC, ReactNode, useEffect, useState } from 'react';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';

import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';

import cn from 'classnames';

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  full?: boolean;
  mobileClose?: boolean;
  classNames?: string;
  noScroll?: boolean;
  children?: ReactNode;
  style?: CSSProperties;
}

export const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  full,
  mobileClose,
  noScroll,
  classNames,
  children,
  style,
}) => {
  const { width } = useWindowDimensions();

  const [addHeight, setAddHeight] = useState<boolean>(false);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setAddHeight(true);
      }, 10);
    }

    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    }

    if (!isOpen) {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  const handleClose = () => {
    if (full && width < 1024) {
      setAddHeight(false);
      setTimeout(() => {
        onClose && onClose();
      }, 500);

      return;
    }

    onClose && onClose();
  };

  const handleTouchStart = e => setTouchStart(e.targetTouches[0].clientY);
  const handleTouchMove = e => setTouchEnd(e.targetTouches[0].clientY);
  const handleTouchEnd = () => touchStart - touchEnd < -50 && handleClose();

  if (!isOpen) {
    return <></>;
  }

  if (full && width < 1024) {
    return (
      <div
        className={cn(
          { 'bottom-0 opacity-1': addHeight, 'opacity-0 -bottom-full': !addHeight },
          'w-full max-h-full left-0 z-100 fixed px-4 py-2.5 bg-white',
          'rounded-t-4xl shadow-turquoise-400 transition-full',
        )}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <div className="mx-auto w-24 h-1 bg-grey-100 rounded-xl mb-8" />
        {children}
      </div>
    );
  }

  return (
    <div
      className={
        'w-full h-full z-100 fixed flex items-center justify-center bg-grey-rgb-200 inset-0 z-1000'
      }
      style={{
        ...style,
        backgroundColor: 'rgba(204, 204, 204, 0.6)',
        backdropFilter: 'blur(10px)',
      }}
    >
      <div
        className={cn(
          'relative bg-white p-8 max-h-[100%] min-h-[100%] t-sm:min-h-[15rem]',
          't-sm:rounded-4xl flex t-sm:justify-center flex-col',
          'overflow-auto',
          classNames,
        )}
      >
        {onClose ? (
          <div className="flex w-full justify-end">
            <CloseIcon
              className="flex hover:fill-turquoise-100 w-5 h-5 fill-grey-100 cursor-pointer"
              onClick={handleClose}
            />
            {mobileClose ? (
              <CloseIcon
                className={cn(
                  't-sm:hidden ml-auto mr-5 mt-5 hover:fill-turquoise-100 ',
                  'w-4 h-4 fill-grey-100 cursor-pointer',
                )}
                onClick={handleClose}
              />
            ) : null}
          </div>
        ) : null}
        <div
          className={cn(
            { 'overflow-auto': !Boolean(noScroll) },
            'h-full w-full scrollbar-hide py-[8px] px-[24px]',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
