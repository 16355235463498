export const BackIcon = className => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4696_18012)">
        <path
          d="M6.62119 15.2037L0.264862 8.68517C0.168554 8.5864 0.100175 8.47941 0.0597259 8.36418C0.0192765 8.24895 -0.000627005 8.1255 1.50483e-05 7.99381C1.50483e-05 7.86212 0.0202399 7.73866 0.0606893 7.62344C0.101139 7.50821 0.169196 7.40121 0.264862 7.30245L6.62119 0.783914C6.79776 0.602844 7.01863 0.508359 7.28379 0.500457C7.54896 0.492556 7.77753 0.587042 7.96951 0.783914C8.16212 0.964985 8.2626 1.19149 8.27095 1.46342C8.2793 1.73536 8.18684 1.96976 7.99358 2.16663L3.27449 7.00615H14.0369C14.3098 7.00615 14.5387 7.10097 14.7236 7.2906C14.9085 7.48023 15.0006 7.71463 15 7.99381C15 8.27364 14.9079 8.50838 14.7236 8.69801C14.5393 8.88764 14.3104 8.98212 14.0369 8.98147H3.27449L7.99358 13.821C8.17015 14.0021 8.2626 14.2325 8.27095 14.5123C8.2793 14.7922 8.18684 15.0226 7.99358 15.2037C7.81702 15.4012 7.5923 15.5 7.31943 15.5C7.04656 15.5 6.81381 15.4012 6.62119 15.2037Z"
          fill="#7B7B7B"
        />
      </g>
      <defs>
        <clipPath id="clip0_4696_18012">
          <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
