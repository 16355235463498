export const WindowIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3429_28682)">
        <path
          d="M7.27259 2.31842L7.27771 2.31839C7.31218 2.3179 7.34636 2.32425 7.37829 2.33709C7.41022 2.34993 7.43931 2.36898 7.46382 2.39316C7.48833 2.41732 7.50782 2.44613 7.52105 2.47789C7.53436 2.50964 7.5412 2.54373 7.5412 2.57815C7.5412 2.61258 7.53436 2.64667 7.52105 2.67842C7.50782 2.71018 7.48833 2.73899 7.46382 2.76316C7.43931 2.78733 7.41022 2.80639 7.37829 2.81922C7.34636 2.83206 7.31218 2.83841 7.27771 2.83793L7.27259 2.83789H3.11676C1.87552 2.83789 0.883002 3.83041 0.883002 5.07165V13.3833C0.883002 14.6245 1.87552 15.617 3.11676 15.617H11.4284C12.6696 15.617 13.6621 14.6245 13.6621 13.3833V9.22745V9.22236C13.6616 9.18788 13.668 9.1537 13.6808 9.12178C13.6937 9.08985 13.7128 9.06076 13.7369 9.03625C13.7611 9.01174 13.7899 8.99225 13.8216 8.97901C13.8534 8.9657 13.8875 8.95887 13.9219 8.95887C13.9563 8.95887 13.9904 8.9657 14.0221 8.97901C14.0539 8.99225 14.0827 9.01174 14.1069 9.03625C14.1311 9.06076 14.1501 9.08985 14.1629 9.12178C14.1758 9.15378 14.1821 9.18788 14.1817 9.22236L14.1816 9.22745V13.3833C14.1816 14.8965 12.9416 16.1365 11.4284 16.1365H3.11676C1.60352 16.1365 0.363525 14.8965 0.363525 13.3833V5.07165C0.363525 3.55841 1.60352 2.31842 3.11676 2.31842H7.27259Z"
          fill="#9DA1AF"
          stroke="#9DA1AF"
        />
        <path
          d="M7.27271 9.22656L15.2727 1.22656M15.2727 1.22656H9.09089M15.2727 1.22656V7.40838"
          stroke="#9DA1AF"
          stroke-width="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_3429_28682">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
