export const PunctureIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2992_30219)">
        <path
          d="M10.0001 3L7.33341 5.66667L4.66675 6.66667L3.66675 7.66667L8.33341 12.3333L9.33341 11.3333L10.3334 8.66667L13.0001 6"
          stroke="#7B7B7B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M6 10L3 13" stroke="#7B7B7B" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M9.66675 2.66602L13.3334 6.33268"
          stroke="#7B7B7B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2992_30219">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
